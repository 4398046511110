<template>
  <!-- Case Area Start -->
  <section class="ekifa-service-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>Results of past cases</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" v-for="(item, key) in cases" :key="key">
          <router-link :to="{path: `${item.title.path}`}">
            <div class="single-service">
              <div class="service-img">
                <img :src="item.img" alt="service image" />
              </div>
              <div class="service-text">
                <h3><b-link :to="item.title.path">{{item.title.name}}</b-link></h3>
                <p>{{item.description}}</p>
                <div class="service-hover">
                  <b-link :to="item.hover.path">
                    <i :class="`fa fa-${item.hover.icon}`"></i>
                  </b-link>
                </div>
              </div>
            </div>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="load-btn">
            <b-link class="ekifa-btn">load more cases</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Case Area End -->
</template>

<script>
export default {
  name: "CaseArea",
  data() {
    return {
      cases: [
        {
          img: require('../assets/img/service-2.jpg'),
          title: {
            path: '/cases/results',
            name: 'Finance & Legal'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '/cases/results',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-3.jpg'),
          title: {
            path: '/cases/results',
            name: 'Construction'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '/cases/results',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-4.jpg'),
          title: {
            path: '/cases/results',
            name: 'Commerce'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '/cases/results',
            icon: 'arrow-right'
          }
        },
        {
          img: require('../assets/img/service-1.jpeg'),
          title: {
            path: '/cases/results',
            name: 'Land Ownership'
          },
          description: 'Interdum iusto pulvinar consequuntur augue optio repellat fuga Purus expedita fusce temp oribus.',
          hover: {
            path: '/cases/results',
            icon: 'arrow-right'
          }
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
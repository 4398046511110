<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Case Area Component -->
  <case-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import CaseArea from "../../components/CaseArea";
export default {
  name: "Cases",
  components: {
    CaseArea,
    BreadcrumbArea
  },
  data() {
    return {
      breadcrumb: {
        page: "Cases",
        pageTitle: "Cases"
      }
    }
  }
}
</script>

<style scoped>

</style>